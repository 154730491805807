import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import upcomingHeaderImg from "../images/UPCOMING.svg";
import { Link } from "gatsby";
import HeaderLogo from "../images/pipp-header-logo.svg";
import UpcomingImg from "../images/upcoming.jpg";

const ListLink = (props) => (
  <li className="header-list-element">
    <Link to={props.to} className="header-list-link">
      {props.children}
    </Link>
  </li>
);

export default () => (
  <Layout>
    <SEO title="Upcoming" />
    <main className="list-main classes-main">
      <header className="nav-header">
        <nav>
          <Link className="logo-link" to="/">
            <img src={HeaderLogo} alt="Home" />
          </Link>
          <div className="heading-container">
            <ul className="header-ul">
              <ListLink to="/about">about</ListLink>
              <ListLink to="/manifesto">manifesto</ListLink>
              <ListLink to="/upcoming">upcoming</ListLink>
              <ListLink to="/past">past</ListLink>
              <ListLink to="/resources">resources</ListLink>
              <ListLink to="/contact">contact</ListLink>
            </ul>
            <img
              className="heading-img"
              src={upcomingHeaderImg}
              alt="Upcoming"
            />
          </div>
        </nav>
      </header>
      <div className="two-column-main">
        <div className="side-container">
          <img className="side-image about-image" src={UpcomingImg} alt="" />
        </div>
        <div className="side-container">
          <article>
            <p>
              Stay tuned to our <a href="https://www.instagram.com/patternmakinginpublicplaces">Instagram</a> as we
              announce more patternmaking events in the future.
            </p>
          </article>
        </div>
      </div>
    </main>
  </Layout>
);
